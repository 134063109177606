import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { Link } from 'gatsby';
import { cosmicpuma } from '@nonsequitur/component-library';

import Burger from './Burger'
import RightNav from './RightNav'

import { useMediaQuery } from '../../utils/hooks'

const { Button } = cosmicpuma;

const AppBar = (props) => {
  const {
    siteTitle,
    actions,
    classes,
    className,
  } = props;
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)
  const { breakpointS, breakpointM } = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`)
  const isMediumScreen = useMediaQuery(`(max-width: ${breakpointM})`)

  const brandText = isSmallScreen ? 'CP' : siteTitle;
  return (
    <div className={className}>
      <header className={classes.header}>
        <div className={classes.brand}>
          <Link to="/">{brandText}</Link>
        </div>
        {/* <div className={classes.search}>
          <TextField />
        </div> */}
        <nav className={classes.nav}>
          {isMediumScreen ?
            <>
              <Burger
                isOpen={isBurgerOpen}
                handleClick={() => setIsBurgerOpen(!isBurgerOpen)}
              />
              <RightNav
                isOpen={isBurgerOpen}
                actions={actions}
              />
            </> :
            actions.map(props => (
              <Button
                {...props}
                className={classes.action}
                key={props.children}
              />
            ))}
        </nav>
      </header>
    </div>
  );
}

AppBar.propTypes = {
  siteTitle: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  classes: PropTypes.shape({
    header: PropTypes.string,
    brand: PropTypes.string,
    nav: PropTypes.string,
  }),
  className: PropTypes.string
}

AppBar.defaultProps = {
  siteTitle: 'Cosmic Puma',
  isLoggedIn: false,
  classes: {
    header: 'appbar-header',
    brand: 'appbar-brand',
    nav: 'appbar-nav',
    action: 'appbar-actions'
  },
  className: undefined
}

export default AppBar;
