import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useTheme } from '@emotion/react';

import { rhythm } from "../utils/typography"
import { isLoggedIn, logout } from "../services/auth"

import AppBar from './AppBar'

const handleNavigation = (path, options) => (event) => {
  event.preventDefault();
  navigate(path, options);
}

const loggedInActions = [
  {
    color: 'primary',
    variant: 'text',
    children: 'Feeling Lucky',
    onClick: handleNavigation('/about')
  },
  {
    color: 'primary',
    variant: 'text',
    children: 'Top Rated',
    onClick: handleNavigation('/about')
  },
  {
    color: 'primary',
    variant: 'text',
    children: 'Trending',
    onClick: handleNavigation('/about')
  },
  {
    color: 'secondary',
    variant: 'solid',
    size: 'small',
    children: 'Write',
    onClick: handleNavigation('/write')
  },
  {
    color: 'secondary',
    variant: 'text',
    children: 'Logout',
    onClick: () => logout(() => navigate('/'))
  },
];

const loggedOutActions = [
  {
    color: 'primary',
    variant: 'outlined',
    size: 'small',
    children: 'Login',
    onClick: handleNavigation('/app/login') // TODO: add options.state
  },
  {
    color: 'secondary',
    variant: 'solid',
    size: 'small',
    children: 'Register',
    onClick: handleNavigation('/app/login') // TODO: add options.state
  }
];

const Layout = ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const { colorBackgroundSecondary } = useTheme();

  return (
    <div
      style={{
        backgroundColor: colorBackgroundSecondary,
        height: `100vh`,
      }}
    >
      <AppBar
        actions={isLoggedIn() ? loggedInActions : loggedOutActions}
        siteTitle={data.site.siteMetadata.title}
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `700px`,
          paddingLeft: rhythm(1.25),
          paddingRight: rhythm(1.25),
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout;
